<template>
  <div class="data-student">
    <v-form ref="form">
      <v-row class="py-5">
        <v-col
          cols="12"
          class="py-0"
        >
          <h3 class="font-weight-bold mb-4">
            Data Diri
          </h3>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="student.nis"
              type="number"
              label="NIS"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="student.nisn"
              type="number"
              label="NISN"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-menu
              v-model="entry_date"
              :close-on-content-click="true"
              nudge-right="0"
              nudge-top="20"
              max-width="100%"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="student.entry_date"
                  label="Tanggal Masuk"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click="autofillEntryDate"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="student.entry_date"
                :first-day-of-week="0"
                locale="id-id"
                color="primary"
                @input="entry_date = false"
              ></v-date-picker>
            </v-menu>
          </div>
        <!-- <div>
          <v-text-field
            v-model="student.entry_date"
            label="Tanggal Masuk"
            dense
            type="date"
            outlined
          ></v-text-field>
        </div> -->
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="student.nik_ktp"
              label="No. KTP"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="student.place_of_birth"
              label="Tempat Lahir"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-menu
              v-model="birth_date"
              :close-on-content-click="true"
              nudge-right="0"
              nudge-top="20"
              max-width="100%"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="student.date_of_birth"
                  label="Tanggal Lahir"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click="autofillBirthDate"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="student.date_of_birth"
                :first-day-of-week="0"
                locale="id-id"
                color="primary"
                @input="birth_date = false"
              ></v-date-picker>
            </v-menu>
          </div>
        <!-- <div>
          <v-text-field
            v-model="student.date_of_birth"
            label="Tanggal Lahir"
            type="date"
            dense
            outlined
          ></v-text-field>
        </div> -->
        </v-col>
        <v-col
          xl="4"
          lg="4"
          md="4"
          sm="4"
          cols="12"
          class="py-0"
        >
          <v-autocomplete
            v-model="student.province_uuid"
            :items="provinces"
            label="Provinsi"
            item-text="name"
            item-value="uuid"
            dense
            outlined
            return-object
            :disabled="!provinces.length"
            @change="changeListRegencies()"
          >
          </v-autocomplete>
        </v-col>
        <v-col
          xl="4"
          lg="4"
          md="4"
          sm="4"
          cols="12"
          class="pb-2 pt-0"
        >
          <v-autocomplete
            v-model="student.regencies_uuid"
            :items="regenciesByProvince"
            label="Kabupaten/Kota"
            return-object
            item-text="name"
            item-value="uuid"
            dense
            outlined
            :messages="isNullRegencies"
            :disabled="!regenciesByProvince.length"
            @change="changeListDistrict()"
          >
          </v-autocomplete>
        </v-col>
        <v-col
          xl="4"
          lg="4"
          md="4"
          sm="4"
          cols="12"
          class="pb-2 pt-0"
        >
          <v-autocomplete
            v-model="student.district_uuid"
            :items="districtsByRegencies"
            label="Kecamatan"
            return-object
            item-text="name"
            item-value="uuid"
            dense
            outlined
            :messages="isNullDistrict"
            :disabled="!districtsByRegencies.length"
          >
          </v-autocomplete>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-combobox
              v-model="student.gender"
              :items="genders"
              label="Jenis Kelamin"
              dense
              return-object
              item-text="name"

              outlined
            ></v-combobox>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-combobox
              v-model="student.religion"
              :items="religions"
              label="Agama"
              dense
              outlined
            ></v-combobox>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="student.weight"
              label="Berat Badan"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="student.height"
              label="Tinggi Badan"
              type="number"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="student.blood_type"
              label="Golongan Darah"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="student.hobby"
              label="Hobi"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="student.disease_history"
              label="Riwayat Penyakit"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="student.transport"
              label="Moda Transportasi"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="student.address"
              label="Alamat"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="student.home_distance"
              label="Jarak Tempat Tingga ke Sekolah"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="student.phone"
              label="No Hp"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="student.home_phone"
              label="Telp. Rumah"
              dense
              outlined
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="py-0"
        >
          <v-text-field
            v-model="student.email"
            label="Email"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          class="pt-0 pb-3"
        >
          <FileInput
            :value="initialFile"
            label="Foto Peserta Didik/Siswa"
            outlined
            :prependicon="icons.mdiImageMultipleOutline"
            dense
            @input="getFile"
          ></FileInput>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import FileInput from '@/views/components/FileInput.vue'
import { mdiImageMultipleOutline } from '@mdi/js'

export default {
  components: {
    FileInput,
  },
  expose: ['student'],
  props: {
    editUuid: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      icons: {
        mdiImageMultipleOutline,
      },
      school: '',
      school_uuid: '',
      classes: [],
      provinces: [],
      province: {},
      regencies: [],
      regency: {},
      districts: [],
      district: {},
      villages: [],
      village: {},
      isNullRegencies: '',
      isNullDistrict: '',
      isNullVillage: '',
      regenciesByProvince: [],
      districtsByRegencies: [],
      villageByDistrict: [],
      entry_date: false,
      birth_date: false,
      genders: ['Laki-Laki', 'Perempuan'],
      religions: ['Islam', 'Kristen Protestan', 'Kristen Khatolik', 'Hindu', 'Budha', 'Konghucu'],
      initialStudent: {
        nis: '',
        nisn: '',
        nik_ktp: '',
        email: '',
        class: '',
        gender: '',
        religion: '',
        ethnic_group: '',
        entry_date: '',
        date_of_birth: '',
        place_of_birth: '',
        weight: '',
        height: '',
        blood_type: '',
        hobby: '',
        disease_history: '',
        transport: '',
        home_distance: '',
        is_transfer: 0,
        home_phone: '',
        phone: '',
        point: '',
        photo: null,
        province_uuid: '',
        regencies_uuid: '',
        district_uuid: '',
        village_uuid: '',
        address: '',
        rt: '',
        rw: '',
        zip_code: '',
        latitude: '',
        longitude: '',
      },
      student: {
        ...this.initialStudent,
      },
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.student.photo === 'string') {
          return new File([this.student.photo], this.student.photo, { type: 'text/plain' })
        }

        return this.student.photo
      },
      set() {
        return this.student.photo
      },
    },
  },
  watch: {
    editUuid() {
      if (this.editUuid.length !== 0) this.get()
    },
    student: {
      handler() {
        this.changeListRegencies(this.student.province_uuid)
        this.changeListDistrict(this.student.district_uuid)
      },
    },
  },
  async mounted() {
    this.getSchoolUuid()
    if (this.editUuid.length !== 0) await this.get()
    this.listClass()
    this.listProvince()
    this.listRegency()
    this.listDistrict()
    this.listClass()
  },
  methods: {
    autofillEntryDate() {
      this.student.entry_date = new Date().toISOString().substr(0, 10)
    },
    autofillBirthDate() {
      this.student.date_of_birth = new Date().toISOString().substr(0, 10)
    },

    getFile(data) {
      this.student.photo = data
    },
    resetStudent() {
      this.$refs.form.reset()
      // this.student = this.initialStudent
    },

    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }

        if (el.school === null) {
          return
        }
        this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
        this.school_uuid = el.school.uuid
      })

      return true
    },

    async listClass() {
      await this.$services.ApiServices.list('class', { school_uuid: this.school_uuid }).then(
        ({ data }) => {
          this.classes = data.data
        },
        err => console.error(err),
      )
    },

    async listProvince() {
      await this.$services.ApiServices.list('province', { per_page: 'all' }).then(
        ({ data }) => {
          this.provinces = data.data
        },
        err => console.error(err),
      )
    },

    async listRegency() {
      await this.$services.ApiServices.list('regency', { per_page: 'all' }).then(
        ({ data }) => {
          this.regencies = data.data
        },
        err => console.error(err),
      )
    },

    async listDistrict() {
      await this.$services.ApiServices.list('district', { per_page: 'all' }).then(
        ({ data }) => {
          this.districts = data.data
        },
        err => console.error(err),
      )
    },

    changeListRegencies() {
      this.regenciesByProvince = this.regencies.filter(item => item.province_uuid === this.student.province_uuid?.uuid)
      if (this.regenciesByProvince.length < 1) {
        this.isNullRegencies = 'Provinsi yang anda pilih tidak memiliki kabupaten'
      }
    },

    changeListDistrict() {
      this.districtsByRegencies = this.districts.filter(
        item => item.regencies_uuid === this.student.regencies_uuid?.uuid,
      )
      if (this.districtsByRegencies.length < 1) {
        this.isNullDistrict = 'Kabupaten/kota yang anda pilih tidak memiliki kecamatan'
      }
    },

    async get() {
      await this.$services.ApiServices.get('student', this.editUuid).then(
        ({ data }) => {
          const photoStudent = data.data.image == null ? null : data.data.image.split('/').pop()
          this.student = {
            nis: data.data.nis,
            nisn: data.data.nisn,
            nik_ktp: data.data.nik_ktp,
            email: data.data.email,
            gender: data.data.gender,
            religion: data.data.religion,
            ethnic_group: data.data.ethnic_group,
            entry_date: data.data.entry_date,
            date_of_birth: data.data.date_of_birth,
            place_of_birth: data.data.place_of_birth,
            weight: data.data.weight,
            height: data.data.height,
            blood_type: data.data.blood_type,
            hobby: data.data.hobby,
            disease_history: data.data.disease_history,
            transport: data.data.transport,
            home_distance: data.data.home_distance,
            is_transfer: 0,
            home_phone: data.data.home_phone,
            phone: data.data.phone,
            point: data.data.point,
            photo: data.data.image == null ? null : data.data.image.split('/').pop(),
            province_uuid: {
              name: data.data.place_uuid?.province?.name,
              uuid: data.data.place_uuid?.province?.uuid,
            },
            regencies_uuid: {
              name: data.data.place_uuid?.regencies?.name,
              uuid: data.data.place_uuid?.regencies?.uuid,
            },
            district_uuid: {
              name: data.data.place_uuid?.district?.name,
              uuid: data.data.place_uuid?.district?.uuid,
            },
            address: data.data.place_uuid?.address,
            rt: data.data.place_uuid?.rt,
            rw: data.data.place_uuid?.rw,
            zip_code: data.data.place_uuid?.zip_code,
            latitude: data.data.place_uuid?.latitude,
            longitude: data.data.place_uuid?.longitude,
          }
        },
        err => console.error(err),
      )
    },
  },
}
</script>

<style>
</style>
