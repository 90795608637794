<template>
  <v-card>
    <v-tabs
      v-model="tab"
      show-arrows
      fixed-tabs
    >
      <v-tab
        v-for="(tabItem,index) in tabs"
        :key="tabItem.icon"
        @click="reRenderComponent(index)"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tabItem.icon }}
        </v-icon>
        <span>{{ tabItem.title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <student-data :key="componentKey"></student-data>
      </v-tab-item>
      <v-tab-item>
        <student-mutation :key="componentKey1"></student-mutation>
      </v-tab-item>
      <v-tab-item>
        <student-graduate :key="componentKey2"></student-graduate>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import StudentData from './StudentData.vue'
import StudentGraduate from './StudentGraduate.vue'
import StudentMutation from './StudentMutation.vue'

export default {
  name: 'Tabs',
  components: {
    StudentData,
    StudentGraduate,
    StudentMutation,
  },
  props: {},
  data() {
    return {
      tab: '',
      tabs: [{ title: 'Siswa' }, { title: 'Mutasi' }, { title: 'Kelulusan' }],
      componentKey: 0,
      componentKey1: 0,
      componentKey2: 0,
    }
  },
  methods: {
    reRenderComponent(index) {
      switch (index) {
        case 0:
          this.componentKey += 1
          break
        case 1:
          this.componentKey1 += 1
          break
        case 2:
          this.componentKey2 += 1
          break

        default:
          break
      }
    },
  },
}
</script>

<style>
</style>
